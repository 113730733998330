import React from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { CrossIcon, ProductPlaceholderSmall } from 'assets';
import './modalOrder.scss';
import {isoDateToNormal} from "../../../helpers/isoDateToNormal";

const ModalOrder = ({ show, onHide, isCart = false, modalContent }) => {

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='modal-order'
      dialogClassName='modal-order-dialog'
    >
      <Modal.Body className='modal-order__body'>
        {isCart && (
          <div className='modal-order__header'>
            <h4 className='modal-order__title'>Вы сделали заказ</h4>
            <p className='modal-order__text'>Скоро мы позвоним вам, чтобы подтвердить заказ</p>
          </div>
        )}
        <div className='modal-order__details'>
          <h4 className='modal-order__title'>Детали заказа</h4>
          <div className='modal-order__details-item modal-order__text'>
            <p>Способ получениия</p>
            <span>{modalContent?.shipment}</span>
          </div>
          <div className='modal-order__details-item modal-order__text'>
            <p>Дата заказа</p>
            <span>{isoDateToNormal(modalContent?.createdAt)}</span>
          </div>
          <div className='modal-order__details-item modal-order__text'>
            <p>Адрес доставки</p>
            <span>{modalContent?.country}, {modalContent?.city}, {modalContent?.street}, {modalContent?.house}{modalContent?.apartment && `, ${modalContent?.apartment}`}</span>
          </div>
          <div className='modal-order__details-item modal-order__text'>
            <p>Получатель</p>
            <span>{modalContent?.surname} {modalContent?.name}</span>
          </div>
          <div className='modal-order__details-item modal-order__text'>
            <p>Телефон получателя</p>
            <span>{modalContent?.phone}</span>
          </div>
          <div className='modal-order__details-item modal-order__text'>
            <p>Комментарий</p>
            <span>{modalContent?.comment}</span>
          </div>
        </div>
        <div className="modal-order__table-container">
          <table className='table modal-order__table'>
            <tbody className='modal-order__table-body'>
            {modalContent?.products && modalContent?.products.map((el) => (
                <tr className='modal-order__table-item' key={el._id}>
                  <td>
                    <div className='modal-order__table-item__img'>
                      <img src={el?.imagePreview ? el?.imagePreview : ProductPlaceholderSmall} alt='Продукт' />
                    </div>
                  </td>
                  <td>
                    <p className='modal-order__table-item__title'>{el?.name}</p>
                  </td>
                  <td>
                    <div className='modal-order__table-item__count'>
                      <p>Кол-во</p>
                      <span>{el?.cartQuantity}</span>
                    </div>
                  </td>

                  <td>
                    <div className='modal-order__table-item__code'>
                      <p>Артикул</p>
                      <span>{el?.sku}</span>
                    </div>
                  </td>
                  <td>
                    <div className='modal-order__table-item__price'>
                      {el?.oldPrice ? (
                          <div className='modal-order__table-item__price-discount'>
                            <p>{el?.price}c</p>
                            <span>{el?.oldPrice}c</span>
                          </div>
                      ) : (
                          <p>{el?.price}c</p>
                      )}
                    </div>
                  </td>
                </tr>
            ))}
            </tbody>
          </table>
        </div>

        <div className='modal-order__total'>
          <div className='modal-order__total-item modal-order__total-item--ship'>
            <p>Товары({modalContent?.totalQuantity})</p>
            <p>{modalContent?.totalPrice}с</p>
          </div>
          {
            modalContent?.promocode && (
                  <div className='modal-order__total-item modal-order__total-item--ship'>
                    <p>Промокод</p>
                    <p>{modalContent?.promocode?.code} ({modalContent?.totalPrice - modalContent?.totalPriceWithDiscount}с)</p>
                  </div>
              )
          }
          <div className='modal-order__total-item modal-order__total-item--price'>
            <p>Итого</p>
            <p>{modalContent?.totalPriceWithDiscount}c</p>
          </div>
        </div>
        {isCart && (
          <Link to='/catalog' className='modal-order__btn btn-transitioned-animated'>
            Вернуться в каталог
          </Link>
        )}
        {!isCart && (
          <button type='button' className='modal-order__btn-close' onClick={() => onHide()}>
            <CrossIcon />
          </button>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalOrder;
