import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {clearCart, confirmOrder, getCartTotal, removeItem} from 'store/slices/cartSlice';
import Accordion from 'react-bootstrap/Accordion';
import Breadcrumbs from '../@components/breadcrumbs';
import { ArrowIconBlack, CrossIcon, ProductPlaceholderSmall } from 'assets';
import CustomCartCounter from './components/customCartCounter';
import ModalOrder from '../@components/modalOrder';
import ModalShipment from "../@components/modalShipment";
import './cart.scss';
import InputGroup from "react-bootstrap/InputGroup";
import Form from 'react-bootstrap/Form';
import {Button} from "react-bootstrap";
import {toast} from "react-toastify";
import {checkPromocodes, clearPromocodeCart} from "../../store/slices/promocodesSlice";
import Loader from "../@components/loader";
import {Link} from "react-router-dom";

const Cart = () => {
  const { cart, totalQuantity, totalPrice, confirmOrderLoading } = useSelector((state) => state.cart);
  const { checkPromocodesLoading, promocodeCart } = useSelector((state) => state.promocodes);
  const { website } = useSelector((state) => state.website);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCartTotal());
    dispatch(clearPromocodeCart())
  }, [cart]);
  const handleRemoveItem = (product) => {
    dispatch(removeItem(product._id));
  };

  const [modalShow, setModalShow] = useState(false);
  const [modalContent, setModalContent] = useState('')
  const openModal = (content) => {
    setModalShow(true)
    setModalContent(content)
  };

  const [modalShipmentShow, setModalShipmentShow] = useState(false)
  const [modalShipmentType, setModalShipmentType] = useState(null)
  const openShipmentModal = (type) => {
    setModalShipmentShow(true)
    setModalShipmentType(type)
  }

  const { user } = useSelector((state) => state.auth);
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [phone, setPhone] = useState('')
  const [phoneAddon, setPhoneAddon] = useState('')
  const [email, setEmail] = useState('')
  const [whatsapp, setWhatsapp] = useState('')
  const [person, setPerson] = useState('')
  const [shipment, setShipment] = useState('')
  const [country, setCountry] = useState('')
  const [city, setCity] = useState('')
  const [street, setStreet] = useState('')
  const [apartment, setApartment] = useState('')
  const [house, setHouse] = useState('')
  const [comment, setComment] = useState('')
  const [checkingPromocode, setCheckingPromocode] = useState('')

  const [fullPrice, setFullPrice] = useState(0)

  const notify = (text) => toast(text);


  useEffect(() => {
    if (user) {
      setName(user?.name)
      setSurname(user?.surname)
      setPhone(user?.phone)
      setEmail(user?.email)
    }
  },[user])

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(confirmOrder({user: user?.id, name, surname, phone, phoneAddon, email, whatsapp, person, shipment, country, city, street, apartment, house, comment, promocode: promocodeCart || null, adminEmail: website?.mainEmail})).then((res) => {
      if (res.type === 'cart/confirmOrder/fulfilled') {
        dispatch(clearPromocodeCart())
        openModal(res?.payload)
        dispatch(clearCart())
      }
    })
  }

  const handleCheckPromocode = () => {
    if (checkingPromocode) {
      dispatch(checkPromocodes({code: checkingPromocode, totalPrice})).then((res) => {
        if (res.type === 'promocodes/checkPromocodes/fulfilled') {
          setCheckingPromocode('')
        }
      })
    } else {
      notify('Введите промокод')
    }
  }

  useEffect(() => {
    if (promocodeCart) {
      if (promocodeCart.discountType === 'Процент' && promocodeCart.discountPercent) {
        setFullPrice(Number(totalPrice - ((totalPrice * promocodeCart.discountPercent) / 100)))
      } else {
        setFullPrice(Number(totalPrice - promocodeCart.discountStatic))
      }
    } else {
      setFullPrice(totalPrice)
    }
  },[promocodeCart, totalPrice])

  return (
    <>
      <div className='container'>
        <Breadcrumbs />
        <div className='order'>
          <h1 className='page-title'>Оформление заказа</h1>
          <form action='cart#' className='order-form' onSubmit={handleSubmit}>
            <div className='row order-row'>
              <div className='col-xl-8'>
                <Accordion defaultActiveKey='0' className='order-accordion'>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header>
                      <p className='order-title'>
                        Корзина ({totalQuantity ? totalQuantity : 'пусто'})
                      </p>
                      <span className='order-accordion-button__arrow'>
                        <ArrowIconBlack />
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <table className='table cart-table'>
                        <thead className='cart-table__head'>
                          <tr>
                            <th scope='col'>Фото</th>
                            <th scope='col' className='cart-table__padding-right'>
                              Название
                            </th>
                            <th
                              scope='col'
                              className='cart-table__padding-left cart-table__padding-right'
                            >
                              Артикул
                            </th>
                            <th
                              scope='col'
                              className='cart-table__padding-left cart-table__padding-right'
                            >
                              Кол-во
                            </th>
                            <th scope='col' className='cart-table__padding-left'>
                              Цена
                            </th>
                            <th scope='col'></th>
                          </tr>
                        </thead>
                        <tbody className='cart-table__body'>
                          {cart.map((el) => (
                            <tr className='cart-table__product' key={el._id}>
                              <th scope='row'>
                                <div className='cart-table__img'>
                                  <img src={el.imagePreview ? el.imagePreview : ProductPlaceholderSmall} alt='Продукт' />
                                </div>
                              </th>
                              <td className='cart-table__padding-right'>
                                <p className='cart-table__title'>
                                  <Link to={`/catalog/product/${el._id}`}>{el.name}</Link>
                                </p>
                              </td>
                              <td className='cart-table__padding-left cart-table__padding-right'>
                                <p className='cart-table__code'>{el.sku}</p>
                              </td>
                              <td className='cart-table__padding-left cart-table__padding-right'>
                                <CustomCartCounter defaultValue={el.cartQuantity} productId={el._id} />
                              </td>
                              <td className='cart-table__padding-left cart-table__price-wrap'>
                                {el.oldPrice ? (
                                  <div className='cart-table__price cart-table__price--discount'>
                                    <p>{el.price}c</p>
                                    <span>{el.oldPrice}c</span>
                                  </div>
                                ) : (
                                  <p className='cart-table__price'>{el.price}c</p>
                                )}
                              </td>
                              <td>
                                <button
                                  type='button'
                                  className='cart-table__btn-remove'
                                  onClick={() => handleRemoveItem(el)}
                                >
                                  <CrossIcon />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <div className='order-form__block'>
                  <h3 className='order-title'>Оформление заказа</h3>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='form-floating'>
                        <input
                          required
                          type='text'
                          className='form-control'
                          id='orderName'
                          placeholder='Имя'
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <label htmlFor='orderName'>Имя</label>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-floating'>
                        <input
                          required
                          type='text'
                          className='form-control'
                          id='orderSurname'
                          placeholder='Фамилия'
                          value={surname}
                          onChange={(e) => setSurname(e.target.value)}
                        />
                        <label htmlFor='orderSurname'>Фамилия</label>
                      </div>
                    </div>
                    <div className='col-sm-4 col-8'>
                      <div className='form-floating'>
                        <input
                          required
                          type='number'
                          className='form-control'
                          id='orderPhone'
                          placeholder='+996'
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                        <label htmlFor='orderPhone'>Телефон</label>
                      </div>
                    </div>
                    <div className='col-sm-2 col-4'>
                      <div className='form-floating'>
                        <input
                          type='number'
                          className='form-control'
                          id='orderDob'
                          placeholder='0'
                          value={phoneAddon}
                          onChange={(e) => setPhoneAddon(e.target.value)}
                        />
                        <label htmlFor='orderDob'>Доб.</label>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-floating'>
                        <input
                          required
                          type='email'
                          className='form-control'
                          id='orderEmail'
                          placeholder='name@example.com'
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <label htmlFor='orderEmail'>E-mail</label>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-floating'>
                        <input
                          type='number'
                          className='form-control'
                          id='orderPhonePlus'
                          placeholder='+996'
                          value={whatsapp}
                          onChange={(e) => setWhatsapp(e.target.value)}
                        />
                        <label htmlFor='orderPhonePlus'>Доп телефон или whatsapp</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='order-form__block'>
                  <h3 className='order-title'>Я</h3>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='orderRadioUserType'
                      id='orderRadioUserType1'
                      required={true}
                      value="Физическое лицо"
                      onChange={(e) => setPerson(e.target.value)}
                    />
                    <label className='form-check-label' htmlFor='orderRadioUserType1'>
                      Физическое лицо
                    </label>
                  </div>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='orderRadioUserType'
                      id='orderRadioUserType2'
                      value="Юридическое лицо"
                      onChange={(e) => setPerson(e.target.value)}
                    />
                    <label className='form-check-label' htmlFor='orderRadioUserType2'>
                      Юридическое лицо
                    </label>
                  </div>
                </div>
                <div className='order-form__block'>
                  <h3 className='order-title'>Доставка</h3>
                  <div className='order-form__shipment-method'>
                    <h6 className='order-subtitle'>Способ доставки</h6>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='orderRadioShippingType'
                        id='orderRadioShippingType1'
                        required={true}
                        value="самовывоз"
                        onChange={(e) => setShipment(e.target.value)}
                      />
                      <label className='form-check-label' htmlFor='orderRadioShippingType1'>
                        Самовывоз
                      </label>
                      <button type="button" className="btn-transitioned-animated" onClick={() => openShipmentModal('hours')}>
                        режим работы магазина
                        <svg
                          width='5'
                          height='8'
                          viewBox='0 0 5 8'
                          stroke='#5178DC'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path d='M1 7L4 4L1 1' fill='none'/>
                        </svg>
                      </button>
                    </div>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='orderRadioShippingType'
                        id='orderRadioShippingType2'
                        value="курьер"
                        onChange={(e) => setShipment(e.target.value)}
                      />
                      <label className='form-check-label' htmlFor='orderRadioShippingType2'>
                        Курьерская доставка
                      </label>
                      <button type="button" className="btn-transitioned-animated" onClick={() => openShipmentModal('courier')}>
                        условия доставки
                        <svg
                          width='5'
                          height='8'
                          viewBox='0 0 5 8'
                          stroke='#5178DC'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path d='M1 7L4 4L1 1' fill='none' />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <h6 className='order-subtitle'>Адрес доставки</h6>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='form-floating'>
                        <input
                          required
                          type='text'
                          className='form-control'
                          id='orderShipCountry'
                          placeholder='Страна'
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                        <label htmlFor='orderShipCountry'>Страна</label>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-floating'>
                        <input
                          required
                          type='text'
                          className='form-control'
                          id='orderShipCity'
                          placeholder='Город'
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                        <label htmlFor='orderShipCity'>Город</label>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-floating'>
                        <input
                          required
                          type='text'
                          className='form-control'
                          id='orderShipStreet'
                          placeholder='Улица'
                          value={street}
                          onChange={(e) => setStreet(e.target.value)}
                        />
                        <label htmlFor='orderShipStreet'>Улица</label>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-floating'>
                        <input
                          type='text'
                          className='form-control'
                          id='orderShipApartment'
                          placeholder='Квартира'
                          value={apartment}
                          onChange={(e) => setApartment(e.target.value)}
                        />
                        <label htmlFor='orderShipApartment'>Квартира</label>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-floating'>
                        <input
                          required
                          type='text'
                          className='form-control'
                          id='orderShipHouse'
                          placeholder='Дом'
                          value={house}
                          onChange={(e) => setHouse(e.target.value)}
                        />
                        <label htmlFor='orderShipHouse'>Дом</label>
                      </div>
                    </div>
                  </div>
                  <div className='order-form__shipment-comment'>
                    <h6 className='order-subtitle'>
                      Указать домофон, этаж, подъезд и комментарий к адресу
                    </h6>
                    <div className='form-floating'>
                      <textarea
                        className='form-control'
                        placeholder='Комментарий'
                        id='orderShipComment'
                        style={{ height: 65 + 'px' }}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                      <label htmlFor='orderShipComment'>Комментарий</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 relative'>
                <div className='cart-total'>
                  <h6 className='cart-total__title'>Стоимость заказа</h6>
                  <div className='d-flex justify-content-between cart-total__item'>
                    <span>Товары({totalQuantity})</span>
                    <p>{totalPrice}c</p>
                  </div>
                  {
                    promocodeCart && (
                        <div className='d-flex justify-content-between cart-total__item'>
                            <span>Промокод</span>
                            <p>{promocodeCart?.code} ({totalPrice - fullPrice}с)</p>
                          </div>
                    )
                  }
                  <div className='cart-total__divider'></div>
                  <div className='d-flex justify-content-between cart-total__sum'>
                    <span>Итого</span>
                    <p>{fullPrice}с</p>
                  </div>
                  <InputGroup className="mt-3">
                    <Form.Control
                        placeholder="Промокод"
                        value={checkingPromocode}
                        onChange={(e) => setCheckingPromocode(e.target.value.replace(/\s/g, '').toUpperCase())}
                    />
                    <Button variant="outline-secondary" onClick={handleCheckPromocode} disabled={checkPromocodesLoading === 'loading' || totalQuantity === 0}>
                      Найти
                    </Button>
                  </InputGroup>
                  {totalQuantity !== 0 ? (
                    <button type='submit' className='cart-btn'>
                      Подтвердить заказ
                    </button>
                  ) : (
                    <button type='button' className='cart-btn cart-btn--disabled' disabled>
                      Корзина пуста
                    </button>
                  )}
                  <p className='cart-total__cond'>
                    Подтверждая заказ, я принимаю условия <br/>
                    <a href='/terms-of-use' target="_blank">пользовательского соглашения</a>
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ModalOrder
        show={modalShow}
        onHide={() => setModalShow(false)}
        products={cart}
        totalPrice={totalPrice}
        isCart={true}
        modalContent={modalContent}
      />
      <ModalShipment
          show={modalShipmentShow}
          onHide={() => setModalShipmentShow(false)}
          type={modalShipmentType}
      />
      {
        confirmOrderLoading === 'loading' && <Loader fullScreen={true}/>
      }
    </>
  );
};

export default Cart;
